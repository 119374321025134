let modals = []
let backdrop
let lastForm = 0


function displayModal(html, w = '20rem', h = '5rem', x = '50%', y = '50%', alignX = 'center', alignY = 'center') {
    let id = modals.length
    let modal = document.createElement('div')
    modal.innerHTML = html
    modal.dataset.id = id
    modal.classList.add('mysheus-modal')
    modal.style.left = x
    modal.style.top = y
    modal.style.maxWidth = w
    modal.style.minHeight = h
    alignX = {center: '-50%', right: '-100%'}[alignX] || 0
    alignY = {center: '-50%', bottom: '-100%'}[alignY] || 0
    if(alignX || alignY) {
        modal.style.transform = `translate(${alignX}, ${alignY})`
    }
    modal.style.zIndex = 100+id
    let resolve, reject
    let promise = new Promise((res, rej) => {
        resolve = res
        reject = rej
    })

    modals.push({
        id: id,
        element: modal,
        promise,
        resolve,
        reject,
    })
    backdropShit()
    document.body.appendChild(modal)
    return promise
}

function closeModal(id = modals[modals.length-1].id, data) {
    let index = modals.findIndex(m=>m.id===id)
    let modal = modals[index]
    if(!modal) return
    modal.data = data
    modal.resolve(modal)
    modal.element.remove()
    modals.splice(index, 1)
    backdropShit()
}

function backdropShit() {
    if(modals.length > 0) {
        if(!backdrop) {
            backdrop = document.createElement('div')
            backdrop.classList.add('mysheus-modal-backdrop')
            document.body.appendChild(backdrop)
            backdrop.addEventListener('click', ()=>closeModal())
            //TODO ESC key should close modals
        }
        backdrop.style.zIndex = 100+modals[modals.length-1].id
    } else if(backdrop) {
        backdrop.remove()
        backdrop = undefined
    }
}


function closeModalByElement(modal, data) {
    closeModal(parseInt(modal.dataset.id), data)
}

/**
 *
 * @param title
 * @param image
 * @param html
 * @param buttons
 */
async function displayShit(title, image, html, buttons) {
    buttons = buttons.map((text, i)=>`<button onclick="mysheus.ui.closeModalByElement(this.parentElement.parentElement, ${i})">${text}</button>`).join('')
    let modal = await displayModal(`
<div class="mysheus-modal-title">
    <span>${title}</span>
    <button class="mysheus-modal-close" onclick="mysheus.ui.closeModalByElement(this.parentElement.parentElement, -1)">X</button>
</div>
<div class="mysheus-modal-body">
    ${image?.length?`<img class="mysheus-modal-image" src="${image}" alt="">`:''}
    <div class="mysheus-modal-text">${html}</div>
</div>
<div class="mysheus-modal-buttons">${buttons}</div>`)
    return modal.data === undefined?-1:modal.data
}


function displayInfoBox(html) {
    return displayShit(mysheus.getLocalizedString('info'), 'https://account.mysheus.ru/icons/info.svg', html, [mysheus.getLocalizedString('ok')])
}
function displayWarningBox(html) {
    return displayShit(mysheus.getLocalizedString('warning'), 'https://account.mysheus.ru/icons/warning.svg', html, [mysheus.getLocalizedString('ok')])
}
function displayErrorBox(html) {
    return displayShit(mysheus.getLocalizedString('error'), 'https://account.mysheus.ru/icons/error.svg', html, [mysheus.getLocalizedString('ok')])
}
function displayYesNoBox(title, html) {
    return displayShit(title, '', html, [mysheus.getLocalizedString('yes'), mysheus.getLocalizedString('no')])
}
function displayYesNoCancelBox(title, html) {
    return displayShit(title, '', html, [mysheus.getLocalizedString('yes'), mysheus.getLocalizedString('no'), mysheus.getLocalizedString('cancel')])
}
function displayOkCancelBox(title, html) {
    return displayShit(title, '', html, [mysheus.getLocalizedString('ok'), mysheus.getLocalizedString('cancel')])
}



async function setLanguage(language, el) {
    let oldLanguage = mysheus.language
    mysheus.language = language
    document.querySelector('html').lang = language
    let buttonId = await displayYesNoCancelBox(mysheus.getLocalizedString('languageChangePopupTitle'),mysheus.getLocalizedString('languageChangePopupText'))
    switch (buttonId) {
        //case 1://no
        case 0://yes
            mysheus.setCookie('language', language)
            location.reload()
            return
        case 2://cancel
        case -1://close
            mysheus.language = oldLanguage
            document.querySelector('html').lang = oldLanguage
            if(el) el.value = oldLanguage
            return
    }
    mysheus.setCookie('language', language)

}
function setTheme(theme) {
    document.body.dataset.theme = theme
    mysheus.setCookie('theme', theme)
}

function displayForm(title, html, onsubmit, classList = "") {
    lastForm++
    let formClass = `mysheus-form-${lastForm}`
    displayShit(title, '', `<form class="${classList} ${formClass}">${html}</form>`, [])
    let modal = modals[modals.length - 1]
    let form = document.querySelector('.'+formClass)
    form.onsubmit = async (event) => {
        event.preventDefault()
        let form = event.target

        let buttons = form.querySelectorAll('button, input[type="submit"]')
        let disabled = []
        for(let button of buttons) {
            if(button.disabled) continue
            button.disabled = true
            disabled.push(button)
        }
        let result
        try{ result = await onsubmit(form) }catch (e) { console.error(e) }
        if(result) closeModal(modal.id, 0)
        else for(let button of disabled) {
                button.disabled = false
            }
    }
    console.log(modal.element)
}


module.exports = { displayModal, backdropShit, closeModal, closeModalByElement, displayInfoBox, displayShit,
    displayWarningBox,displayErrorBox, displayYesNoBox, displayOkCancelBox, displayYesNoCancelBox, setLanguage, setTheme,
    displayForm }
