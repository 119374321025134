const {getCookie, setCookie} = require('./cookies')
const headerLocaleData = require('./lang')
const ui = require('./ui')
const account = require('./account')
const utils = require("mysheusutils")

//determine what language to use
let language
let cookieLang = getCookie('language')
if(!cookieLang || !headerLocaleData[cookieLang]) { //no language stored in cookies or invalid value
    //determine language based on preferred languages
    let selectedLanguage
    for(let language of navigator.languages) {//find first supported language from languages list proposed by browser
        let shortLanguage = language.slice(0,2)
        if(headerLocaleData[shortLanguage]) {
            selectedLanguage = shortLanguage
            break
        }
    }
    if(!selectedLanguage) selectedLanguage = 'en'//fallback to English
    language = selectedLanguage
    setCookie('language', selectedLanguage)
} else language = cookieLang

let theme
let cookieTheme = getCookie('theme')
if(!cookieTheme || !['light','dark'].includes(cookieTheme)) { //no theme stored in cookies or invalid value
    let selectedTheme
    if (window?.matchMedia?.('(prefers-color-scheme: dark)')?.matches)
        selectedTheme = "dark"
    else selectedTheme = 'light'
    theme = selectedTheme
    setCookie('theme', selectedTheme)
} else theme = cookieTheme

function getLocalizedString(path) {
    return window?.mysheusConfig?.localeData?.[module.exports.language]?.[path] || //page specific in user language
        window?.mysheusConfig?.localeData?.en?.[path] || //fallback to page specific in English
        headerLocaleData?.[module.exports.language]?.[path] || //fallback to general in user language
        headerLocaleData?.en?.[path] || //fallback to general in English
        path//fallback to unlocalized string
}

let whoamiPromise = new Promise(async(resolve, reject) => {//get /whoami and shit
    try {
        // noinspection UnnecessaryLocalVariableJS
        let whoami = await (await fetch(`https://account.mysheus.ru${location.port?':2053':''}/api/whoami`, {
            credentials: 'include',
            headers: {
                'x-csrf-token': getCookie('csrf')
            }
        })).json()
        mysheus.whoami = whoami//Do not trust PHP Storm, local variable whoami is NOT redundant. It's like a coconut.
        if(!window?.mysheusConfig?.doNotChangeDOM && !window?.mysheusConfig?.doNotDrawHeader) {
            let ass = document.querySelector('#header-nickname')
            ass.innerHTML = mysheus.whoami.userInfo ? mysheus.whoami.userInfo.nickname: getLocalizedString('menu')
            ass.dataset.officialAccount = mysheus.whoami.userInfo?.official||0
            //document.querySelector('#header-nickname').innerHTML = whoami.accountInfo ? whoami.accountInfo.nickname+(whoami.accountInfo.official?`<img class="checkmark" src="https://mysheus.ru/img/header/checkmark.svg" title="${getLocalizedString('officialAccount')}" alt="${getLocalizedString('officialAccount')}">`:''): getLocalizedString('notLoggedIn')
            //TODO official accounts
        }
        resolve()
    } catch (e) {
        reject(e)
    }
})

let GET = utils.getGET(location.search)


module.exports = {
    language, theme, getCookie, setCookie, getLocalizedString, whoamiPromise, whoami: null, ui, account, utils, GET
}


if(!window?.mysheusConfig?.doNotChangeDOM) {
    //applying language and theme to the document
    document.querySelector('html').lang = language
    document.body.dataset.theme = theme
    Array.prototype.forEach.call(document.querySelectorAll('*[data-localize]'), el=>el.innerHTML = getLocalizedString(el.dataset.localize))

    if(!window?.mysheusConfig?.doNotDrawHeader) {//drawing header
        let header = document.createElement('header')//create header
        header.classList.add('mysheus-header')
        header.innerHTML =
            `<div class="flex-row-centred">
                <a href="/" class="noshit-link">
                    <span class="site-name">${getLocalizedString('siteName')}</span>
                </a>
            </div>
            <div class="flex-row-centred">
                <a class="flex-row-centred" onclick="mysheus.account.displayHeaderRightMenu()">
                <span id="header-nickname">${getLocalizedString('loading')}</span>
                    <img class="header-avatar" alt="avatar" src="/favicon.ico">
                </a>
            </div>`
        document.body.prepend(header)
    }
}
