module.exports = {
    "languageName": "English",
    "language": "Language",
    "siteName": "Mysheus",
    "loading": "Loading...",
    "close": "Close",
    "logout": "Log Out",
    "notLoggedIn": "Not Logged In",
    "login": "Log In",
    "signup": "Sign Up",
    "username": "Username",
    "password": "Password",
    "nickname": "Nickname",
    "email": "Email",
    "confirmPassword": "Confirm Password",
    "registerConfirmText": "To complete registration, follow the link in the email sent to {{email}}.",
    "usernameLength": "Length of your username should be between 1 and 20 characters.",
    "nicknameLength": "Length of your nickname should be between 1 and 20 characters.",
    "emailLength": "Length of your email should be between 1 and 50 characters.",
    "passwordLength": "Length of your password should be between 1 and 50 characters.",
    "passwordsMismatch": "The entered passwords do not match.",
    "usernameSymbols": 'Username can contain only letters, digits, "-" and "_".',
    "userAlreadyExists": "User with this email already exists.",
    "internalError": "Internal server error. Please try again in few moments. If the error persists report it to 'admin@mysheus.ru'.",
    "emailError": "Can't send email. Check if it's correctly entered and try again in few moments. If the error persists report it to 'admin@mysheus.ru'.",
    "userNotFound": "User with this email doesn't exist.",
    "confirmEmail": "You need to confirm your email address before logging in.",
    "wrongPassword": "You entered the wrong password.",
    "noAccount": "No account?",
    "alreadyHaveAccount": "Already registered?",
    "mangeAccount": "Manage account",
    "officialAccount": "Official account",
    "theme": "Theme",
    "themes.dark": "Dark",
    "themes.light": "Light",
    "yes": 'Yes',
    "no": 'No',
    "cancel": 'Cancel',
    "menu": "menu",
    "languageChangePopupTitle": "Change Language",
    "languageChangePopupText": "To finish language change it is required to refresh the page. Before that you would see text in both previous language and English.<br>Refresh the page now?",
    "logoutDialogTitle": "Log Out",
    "logoutDialogText": "Are you sure you want to log out?",
}