module.exports = {
    "languageName": "Русский",
    "language": "Язык",
    "siteName": "Мышеус",
    "loading": "Загрузка...",
    "close": "Закрыть",
    "logout": "Выйти",
    "notLoggedIn": "Вы не вошли",
    "login": "Войти",
    "signup": "Зарегистрироваться",
    "username": "Имя пользователя",
    "password": "Пароль",
    "nickname": "Никнейм",
    "email": "Почта",
    "confirmPassword": "Подтвердить пароль",
    "registerConfirmText": "Для завершения регистрации перейдите по ссылке в письме, отправленным на {{email}}",
    "usernameLength": "Имя пользователя должно быть длиной от 1 до 20 символов.",
    "nicknameLength": "Никнейм должен быть длиной от 1 до 20 символов.",
    "emailLength": "Почта должна быть длиной от 1 до 50 символов.",
    "passwordLength": "Пароль должен быть длиной от 1 до 50 символов.",
    "passwordsMismatch": "Введённые пароли не совпадают.",
    "usernameSymbols": 'Имя пользователя может состоять только из букв, цифр, "-" и "_".',
    "userAlreadyExists": "Пользователь с такой почтой уже существует.",
    "internalError": "Внутренняя ошибка сервера. Пожалуйста попробуйте ещй раз через некоторое время. Если ошибка сохраняется, сообщите о ней на admin@mysheus.ru",
    "emailError": "Не удалось отправить письмо. Проверьте, что вы верно указали почту и попробуйте ещё раз через некоторое время. Если ошибка сохраняется, сообщите о ней на admin@mysheus.ru",
    "userNotFound": "Пользователь с такой почтой не существует.",
    "confirmEmail": "Вы должны подтвердить почту, перед тем как входить.",
    "wrongPassword": "Вы ввели неверный пароль.",
    "noAccount": "Нет аккаунта?",
    "alreadyHaveAccount": "Уже зарегистрированы?",
    "mangeAccount": "Управление аккаунтом",
    "officialAccount": "Официальный аккаунт",
    "theme": "Тема",
    "themes.dark": "Тёмная",
    "themes.light": "Светлая",
    "yes": 'Да',
    "no": 'Нет',
    "cancel": 'Отмена',
    "menu": "меню",
    "languageChangePopupTitle": "Сменить язык",
    "languageChangePopupText": "Для завершения смены языка необходимо обновить страницу. До этого момента вы будете в разных местах видеть текст на предыдущем и русском языке.<br>Обновить страницу сейчас?",
}