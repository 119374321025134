const ui = require('./ui')
const headerLocaleData = require('./lang')
const {getCookie} = require("./cookies");

function displayHeaderRightMenu() {
    let languageOptions = ''
    for(let lang of Object.keys(headerLocaleData)) {
        languageOptions+=`<option value="${lang}" ${mysheus.language === lang?'selected':''}>${headerLocaleData[lang].languageName}</option>`
    }
    let html = `
<div>
    <label>${mysheus.getLocalizedString('language')}
        <select onchange="mysheus.ui.setLanguage(this.value, this)">
            ${languageOptions}
        </select>
    </label>
</div>
<div>
    <label>${mysheus.getLocalizedString('theme')}
        <select onchange="mysheus.ui.setTheme(this.value)">
            <option value="dark" ${mysheus.theme === "dark"?'selected':''}>${mysheus.getLocalizedString('themes.dark')}</option>
            <option value="light" ${mysheus.theme === "light"?'selected':''}>${mysheus.getLocalizedString('themes.light')}</option>
        </select>
    </label>
</div>`//TODO theme, login button, register button, account manager button
    if(mysheus.whoami?.userInfo)
        html += `<div><a href="https://account.mysheus.ru"><button>${mysheus.getLocalizedString('mangeAccount')}</button></a></div>
<div><button onclick="mysheus.account.displayLogoutPopUp()">${mysheus.getLocalizedString('logout')}</button></div>`
    else
        html += `<div><button onclick="mysheus.account.displayLoginPopUp()">${mysheus.getLocalizedString('login')}</button></div>
<div><button onclick="mysheus.account.displaySignupPopUp()">${mysheus.getLocalizedString('signup')}</button></div>`
    ui.displayModal(html, undefined, undefined, '100%', '3rem', 'right', 'top')
}

function displayLoginPopUp() {
    ui.displayForm(mysheus.getLocalizedString('login'), `
<div class="mysheus-login-form-element">
    ${mysheus.getLocalizedString('email')}
    <input name="email" class="mysheus-login-form-input" type="email" maxlength="50" required>
</div>
<div class="mysheus-login-form-element">
    ${mysheus.getLocalizedString('password')}
    <input name="password" class="mysheus-login-form-input" type="password" maxlength="50" minlength="6" required>
</div>
<div class="mysheus-login-form-submit-container">
    <button class="mysheus-login-form-submit">${mysheus.getLocalizedString('login')}</button>
</div>
`, doLogin, "mysheus-login-form")
}

function displaySignupPopUp() {
    ui.displayForm(mysheus.getLocalizedString('signup'), `
<div class="mysheus-login-form-element">
    ${mysheus.getLocalizedString('email')}
    <input name="email" class="mysheus-login-form-input" type="email" maxlength="50" required>
</div>
<div class="mysheus-login-form-element">
    ${mysheus.getLocalizedString('nickname')}
    <input name="nickname" class="mysheus-login-form-input" type="text" maxlength="30" required>
</div>
<div class="mysheus-login-form-element">
    ${mysheus.getLocalizedString('password')}
    <input name="password" class="mysheus-login-form-input" type="password" maxlength="50" minlength="6" required>
</div>
<div class="mysheus-login-form-element">
    ${mysheus.getLocalizedString('confirmPassword')}
    <input name="password2" class="mysheus-login-form-input" type="password" maxlength="50" minlength="6" required>
</div>
<div class="mysheus-login-form-submit-container">
    <button class="mysheus-login-form-submit">${mysheus.getLocalizedString('signup')}</button>
</div>
`, doSignup, "mysheus-login-form")
}

async function doLogin(form) {
    let ass = new FormData(form)
    let res = await (await fetch(`https://account.mysheus.ru${location.port?':2053':''}/api/login`, {
        headers: {
            accept: 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({
            email: ass.get('email'),
            password: ass.get('password')
        })
    })).json()
    if(res.success) {
        location.reload()
        return true
    }
    ui.displayErrorBox(mysheus.getLocalizedString(res.error))
    return false
}

async function doSignup(form) {
    let ass = Object.fromEntries(new FormData(form).entries())
    if(ass.password !== ass.password2) {
        ui.displayErrorBox(mysheus.getLocalizedString('passwordsMismatch'))
        return false
    }

    let res = await (await fetch(`https://account.mysheus.ru${location.port?':2053':''}/api/register`, {
        headers: {
            accept: 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(ass)
    })).json()
    if(res.success) {
        await ui.displayInfoBox(mysheus.getLocalizedString('registerConfirmText').replace('{{email}}', ass.email))
        return true
    }
    ui.displayErrorBox(mysheus.getLocalizedString(res.error))
    return false
}

async function displayLogoutPopUp() {
    if(await ui.displayYesNoBox(mysheus.getLocalizedString('logoutDialogTitle'), mysheus.getLocalizedString('logoutDialogText')) !== 0)
        return
    let res = await (await fetch(`https://account.mysheus.ru${location.port?':2053':''}/api/logout`, {
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-csrf-token': getCookie('csrf')
        }
    })).json()
    if(!res.success) ui.displayErrorBox(mysheus.getLocalizedString(res.error))
    else location.reload()
}




module.exports = {
    displayHeaderRightMenu, displayLoginPopUp, displaySignupPopUp, displayLogoutPopUp, doLogin, doSignup
}